<template>
  <div class="list-table">
    <header class="table-header">
      <div v-if="emails.length !== 0" class="buttons-section">
        <!-- <OptionButton
          v-if="type !== 'ner'"
          class="delete-button"
          title="send to queue"
          @click.native="toLTL"
        /> -->
        <OptionButton
          v-if="type !== 'deleted' && type !== 'nonLtl'"
          class="delete-button"
          title="delete"
          @click.native="deleteRows"
        />
        <!-- <OptionButton
          v-if="type !== 'deleted' && type !== 'sent' && !isGeneral"
          class="unassign-button"
          title="unassign"
          @click.native="unassignRows"
        /> -->
        <!-- <OptionButton
          v-if="
            isGeneral &&
              !isSuper &&
              type !== 'deleted' &&
              type !== 'sent' &&
              type !== 'nonLtl'
          "
          class="assign-button"
          title="assign"
          @click.native="assignRows"
        /> -->
      </div>
      <div class="search-section">
        <!--  Delete dead code -->
        <!-- <SearchSelectBox style="display: inline-block" :options="schema" /> -->
        <SearchSelectBox
          style="display: inline-block"
          :options="selectOptions"
        />
        <SearchBar @searchEmails="search" />
      </div>
    </header>
    <table class="table">
      <thead>
        <tr>
          <th class="title">
            <!-- <input
              class="delete-icon with-pointer checkbox"
              type="checkbox"
              @click="checkAllRows($event.target.checked)"
            /> -->
            <CustomCheckbox
              v-if="shownEmails.length !== 0"
              class="delete-icon checkbox check-all"
              @change.native="checkAllRows($event.target.checked)"
            />
          </th>
          <th :key="index" v-for="(column, index) in schema">
            <button
              class="column-title toggle-button"
              @click="sortTable($event.target)"
            >
              {{ `${column} ${titleIcon}` | title }}
            </button>
          </th>
          <th v-if="['sent', 'webhookFails'].includes(type)">JSON</th>
          <!-- <th>{{ "delete" | title }}</th> -->
        </tr>
      </thead>
      <tbody v-if="shownEmails.length === 0">
        <tr :key="index" v-for="(row, index) in shownEmails"></tr>
        <td
          v-if="['sent', 'webhookFails'].includes(type)"
          :colspan="schema.length + 2"
        >
          <strong>No emails to show</strong>
        </td>
        <td v-else :colspan="schema.length + 1">
          <strong>No emails to show</strong>
        </td>
      </tbody>
      <tbody v-else>
        <tr :key="index" v-for="(row, index) in shownEmails">
          <td>
            <!-- <input
              class="delete-icon with-pointer checkbox checkbox-item"
              type="checkbox"
            /> -->
            <CustomCheckbox
              class="delete-icon with-pointer checkbox checkbox-item"
              @change.native="checkboxChecked(index)"
            />
          </td>
          <td
            class="with-pointer"
            :key="index"
            v-for="(columnValue, index) in filterObject(row, schema)"
            @click="emailSelected(row.id)"
          >
            <label type="text" class="with-pointer">{{ row[index] }}</label>
          </td>
          <td v-if="['sent', 'webhookFails'].includes(type)">
            <font-awesome-icon
              class="with-pointer"
              icon="eye"
              @click="showJSON(row.id)"
            />
          </td>
          <!-- <td>
            <font-awesome-icon
              class="delete-icon with-pointer"
              icon="trash"
              @click="deleteRow(index)"
            />
          </td> -->
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Swal from "sweetalert2";

import { filterObject } from "@/utils/helpers";

import OptionButton from "@/components/OptionButton";
import CustomCheckbox from "@/components/CustomCheckbox";
import SearchSelectBox from "@/components/SearchSelectBox";
import SearchBar from "@/components/SearchBar";
import { mapState, mapActions, mapMutations } from "vuex";

import * as api from "@/api/services";

export default {
  name: "EmailsTable",
  props: {
    type: String,
    isGeneral: Boolean,
    schema: {},
    dir: String
  },
  components: {
    OptionButton,
    CustomCheckbox,
    SearchSelectBox,
    SearchBar
  },
  computed: {
    ...mapState("emailList", ["emails", "sortingOrder", "shownEmails"])
  },
  data: function() {
    return {
      titleIcon: "▲",
      filterObject,
      selectOptions: [
        "subject",
        // "client_email",
        "assigned_to",
        "carrier_name",
        "dot_number"
      ]
    };
  },
  methods: {
    ...mapActions("emailList", [
      "deleteEmails",
      "unassignEmails",
      "assignEmailsById",
      "setSorting",
      "deleteEmailById"
    ]),
    ...mapMutations("emailList", ["setEmails", "setShownEmails"]),
    showJSON: function(id) {
      window.open(`${process.env.VUE_APP_BASE_URL}/api/quote/${id}`, "_blank");
    },
    async toLTL() {
      let emails = [];
      let checkboxes = document.querySelectorAll(".checkbox-item > input");
      for (let i = checkboxes.length - 1; i >= 0; i--) {
        if (checkboxes[i].checked) emails.push(this.shownEmails[i].id);
      }
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, send it!"
      }).then(async (result) => {
        if (result.value) {
          try {
            let status = "1";
            if (this.type === "inProcess") status = "2";
            let promises = [];
            emails.forEach(async function(emailId) {
              const payload = {
                is_ltl: true,
                is_active: true,
                status
              };
              const promise = api.sendToLTL(emailId, payload);
              promises.push(promise);
            });
            await Promise.all(promises);
            emails.forEach((emailId) => this.deleteEmailById(emailId));
            this.setShownEmails(this.emails);
            Swal.fire("Great!", `Email Sent to LTL Successfully.`, "success");
          } catch (err) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: `Could Not Send Email to LTL: ${err.message}`
            });
          } finally {
            this.checkAllRows(false);
          }
        }
      });
    },
    assignRows: async function() {
      let ids = [];
      const checkboxes = this.$el.querySelectorAll(".checkbox-item > input");
      checkboxes.forEach((checkbox, index) => {
        if (checkbox.checked)
          ids.push(Number.parseInt(this.shownEmails[index].id));
      });
      try {
        await this.assignEmailsById({ ids });
        this.setShownEmails(this.emails);
        Swal.fire({
          icon: "success",
          title: "Emails Assigned",
          text: "Emails were assigned correctly"
        });
      } catch (err) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `Could not assign emails: ${err.message}`
        });
      }
      this.checkAllRows(false);
    },
    unassignRows: async function() {
      let emails = [];
      const checkboxes = this.$el.querySelectorAll(".checkbox-item > input");
      checkboxes.forEach((checkbox, index) => {
        if (checkbox.checked) emails.push(this.shownEmails[index].id);
      });
      const emailIds = emails.join(",");
      try {
        await this.unassignEmails(emailIds);
        this.setShownEmails(this.emails);
        Swal.fire({
          icon: "success",
          title: "Emails Unassigned",
          text: "Emails were unassigned correctly"
        });
      } catch (err) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `Could not unassign emails: ${err.message}`
        });
      }
      this.checkAllRows(false);
    },
    deleteRows: async function() {
      let emails = [];
      let checkboxes = document.querySelectorAll(".checkbox-item > input");
      for (let i = checkboxes.length - 1; i >= 0; i--) {
        if (checkboxes[i].checked) {
          emails.push(this.shownEmails[i].id);
        }
      }
      emails = emails.join(",");
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!"
      }).then(async (result) => {
        if (result.value) {
          try {
            await this.deleteEmails(emails);
            this.checkAllRows(false);
            this.setShownEmails(this.emails);
            Swal.fire("Deleted!", "Emails have been deleted.", "success");
          } catch (err) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: `Could not delete emails from database: ${err.message}`
            });
          }
        }
      });
    },
    checkboxChecked: function() {
      const checkboxItems = this.$el.querySelectorAll(".checkbox-item > input");
      const checked = this.$el.querySelectorAll(
        ".checkbox-item > input:checked"
      );
      const checkAll = this.$el.querySelector(".check-all > input");
      if (checkboxItems.length === checked.length) checkAll.checked = true;
      else checkAll.checked = false;
    },
    checkAllRows: function(state) {
      let checkboxes = this.$el.querySelectorAll(".checkbox > input");
      checkboxes.forEach((element) => {
        element.checked = state;
      });
    },
    emailSelected: function(id) {
      let name;
      if (this.isGeneral) name = "generalDetails";
      else name = "details";
      this.$router.push({
        name,
        params: { id, type: this.type }
      });
    },
    search: function(value) {
      this.$emit("searchEmails", value);
    },
    sortTable: function(button) {
      let dir = "";
      const buttonValue = button.innerHTML;
      if (this.titleIcon === "▼") {
        this.titleIcon = "▲";
        dir = "desc";
      } else {
        this.titleIcon = "▼";
        dir = "asc";
      }
      const column = buttonValue
        .toLowerCase()
        .split(" ")
        .join("_")
        .slice(1, buttonValue.length - 3); //removes the triangle symbol
      this.setSorting({ column, dir });
    }
  },
  created: function() {
    this.titleIcon = this.sortingOrder === "asc" ? "▼" : "▲";
  }
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.list-table {
  transition: 0.1s;

  .table-header,
  .table {
    box-shadow: 5px 10px 8px #888888;
  }

  .table-header {
    color: #ffffff;
    background-image: var(--list-table-header-gradient);
    padding: 8px 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;

    .buttons-section {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include for-tablet-landscape-up {
        justify-content: flex-start;
        width: 40%;
      }

      @include for-desktop-up {
        width: 40%;
      }

      .option-button {
        width: 30%;
        margin: 0.5em 0 1em 0;

        @include for-desktop-up {
          margin: 0 0.5em;
        }

        button {
          width: 100%;
        }
      }
    }
  }

  .table {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    background-color: #ffffff;

    thead {
      color: var(--list-table-thead-color);
      background-color: var(--list-table-thead-bg-color);
    }

    td {
      border-bottom: 1px solid #eee;

      label {
        background: transparent;
        border: none;
        width: 100%;
        text-align: center;
        color: black;
      }
    }

    tr:nth-child(even) {
      background-color: #fcfcfc;
    }

    tbody tr:hover {
      background-color: #eee;
    }

    th,
    td {
      text-align: center;
      padding: 10px 15px;
      font-size: 0.85em;
      word-wrap: break-word;
    }

    th {
      font-size: 0.7em;
      border-top: none;
      border-bottom: none;

      .toggle-button {
        padding: 0;
        font-weight: bold;
        background-color: transparent;
        border-style: none;
        outline: none;
      }

      .column-title {
        color: var(--list-table-thead-color);
      }
    }
  }
}

.with-pointer {
  cursor: pointer;
}

div.search-section {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 50%;
}

div.searchbar-wrapper {
  width: calc(100% * 2 / 3);
}

/* MEDIA QUERIES: RESPONSIVE DESIGN */
@media (max-width: 767px) {
  .table-header {
    justify-content: center;
    flex-direction: column-reverse;
    padding: 10px;
  }

  div.search-section {
    display: block;
    width: 100% !important;
    margin-bottom: 10px;
  }

  div.selectbox-wrapper {
    display: inline-block;
    width: 100% !important;
    margin-bottom: 10px;
  }

  div.searchbar-wrapper {
    width: 100% !important;
    margin-bottom: 10px;
  }
}

@media (max-width: 767px) {
  div.searchbar-wrapper {
    width: 100%;
  }

  div.search-section {
    width: 100%;
  }
}

@media (max-width: 1023px) {
  .table {
    table-layout: fixed;
  }

  .table-heacer {
    justify-content: center;
  }

  div.search-section {
    width: 60%;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  div.search-section {
    width: 50%;
  }
}

@media (min-width: 1280px) and (max-width: 1799px) {
  div.search-section {
    width: 50%;
  }
}

@media (min-width: 1800px) and (max-width: 1919px) {
  div.search-section {
    width: 40%;
  }
}

@media (min-width: 1920px) {
  div.search-section {
    width: 40%;
  }
}
</style>

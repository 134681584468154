<template>
  <div class="selectbox-wrapper">
    <div class="box">
      <select class="selectbox">
        <option
          v-for="option in options"
          class="option"
          :key="option"
          :value="option"
          :selected="option === 'subject'"
        >
          {{ option | title }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchSelectBox",
  props: {
    options: Array
  }
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--app-font-family);
}

.selectbox-wrapper {
  display: inline-block;
}

.box {
  position: relative;

  &:before {
    /* content: url("../assets/logo.png"); */
    font-family: "Font Awesome 5 Free";
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 100%;
    background-color: var(--searchbar-bg-color);
    pointer-events: none;
    line-height: 35px;
    font-weight: 900;
  }
}

.selectbox {
  background-color: var(--searchbar-bg-color);
  color: #fff;
  outline: none;
  border: none;
  padding: 11px;
  font-weight: bold;
  font-size: 0.8em;
  width: 100%;
}

.box:before,
.selectbox,
.selectbox option {
  cursor: pointer;
}
</style>

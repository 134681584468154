<template>
  <label class="container">
    {{ text }}
    <input :disabled="!editable" type="checkbox" :name="text" :value="text" />
    <span class="checkmark"></span>
  </label>
</template>

<script>
export default {
  name: "CustomCheckbox",
  props: {
    text: {
      type: String,
      default: ""
    },
    editable: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<style lang="scss" scoped>
$uncheckedColor: #ebecec;
$uncheckedHoverColor: #c5c4c4;
$checkedColor: var(--button-bg-color);
$checkedHoverColor: var(--button-hover-color);

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  display: inline-block;
  position: relative;
  cursor: pointer;
  padding-left: 20px;
  user-select: none;
  text-transform: capitalize;
  white-space: nowrap;
  font-size: 0.9em;

  &:hover input ~ .checkmark {
    background-color: $uncheckedHoverColor;
  }

  &:hover input:checked ~ .checkmark {
    background-color: $checkedHoverColor;
  }

  input {
    position: absolute;
    height: 0;
    width: 0;
    opacity: 0;
  }

  input:checked ~ .checkmark {
    border: none;
    background-color: $checkedColor;
    &:after {
      display: block;
    }
  }
}

.checkmark {
  border: 1px solid #ccc;
  position: absolute;
  top: 50%;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: $uncheckedColor;
  transform: translateY(-50%);

  &:after {
    content: "";
    position: absolute;
    display: none;
    left: 5px;
    top: 2px;
    width: 6px;
    height: 9px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}
</style>

<template>
  <div class="searchbar-wrapper">
    <input
      class="searchbar"
      type="text"
      name="search"
      placeholder="Search..."
      @change="searchEmails"
      @keyup.enter="searchEmails"
    />
    <button class="search-button" @click="searchEmails">
      <font-awesome-icon icon="search" />
    </button>
  </div>
</template>

<script>
export default {
  name: "SearchBar",
  computed: {
    searchInput: function() {
      return this.$el.querySelector(".searchbar");
    }
  },
  methods: {
    searchEmails: function() {
      this.$emit("searchEmails", this.searchInput.value);
    }
  }
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.searchbar-wrapper {
  margin-right: 10px;
}

.searchbar {
  width: calc(100% - 50px);
  padding: 7px;
  font-weight: bold;
  color: #ffffff;
  background-color: #ffffff;
  background-color: var(--searchbar-bg-color);
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border: none;
  outline: none;

  &::placeholder {
    color: #ffffff;
    opacity: 1;
  }
}

button.search-button {
  width: 50px;
  padding: 7px 10px;
  color: #ffffff;
  background-color: #fff;
  background-color: var(--searchbar-bg-color);
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border: none;
  outline: none;

  &:hover {
    background-color: var(--button-hover-color);
  }
}
</style>

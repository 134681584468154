<template>
  <div id="email-list">
    <template v-if="isLoading">
      <LoadingSpinner text="Loading Emails..." />
    </template>
    <template v-else>
      <div class="header">
        <h1 class="title">{{ listTitle }}</h1>
      </div>
      <b-overlay
        id="overlay-background"
        :show="paginationLoading"
        opacity="0.7"
        rounded="sm"
      >
        <EmailsTable
          :type="type"
          :isGeneral="isGeneral"
          @searchEmails="search"
          :schema="$store.state.params.listSchema"
        />
      </b-overlay>
      <PaginationPanel
        :page="page"
        :limit="limit"
        @getFirstEmails="getFirstEmails"
        @getPreviousEmails="getPreviousEmails"
        @getNextEmails="getNextEmails"
        @getLastEmails="getLastEmails"
      />
    </template>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import * as api from "@/api/services";
import emailListConfig from "./EmailListConfig";
import config from "@/utils/config.js";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import EmailsTable from "@/components/EmailsTable";
import PaginationPanel from "@/components/PaginationPanel.vue";
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "EmailList",
  props: {
    type: String,
    isGeneral: {
      type: Boolean,
      default: true
    }
  },
  components: {
    LoadingSpinner,
    EmailsTable,
    PaginationPanel
  },
  computed: {
    ...mapState(["params"]),
    ...mapState("emailList", ["emails", "emailsCount"]),
    listTitle: function() {
      // return this.isGeneral ? this.params.name : "My ".concat(this.params.name);
      return this.params.status == 2 ? "Truck Capacity Queue": "Trucks Posted";
    }
  },
  data: function() {
    return {
      isLoading: true,
      paginationLoading: false,
      page: 0,
      limit: config.emailsPerTable,
      intervalID: null
    };
  },
  methods: {
    ...mapActions("user", ["logoutUser"]),
    ...mapActions("emailList", ["getEmails", "searchEmails", "setSorting"]),
    ...mapMutations("emailList", ["sortEmails", "setShownEmails"]),
    skip: function(page = this.page, limit = this.limit) {
      return limit * page;
    },
    getEmailsNumber: async function() {
      const status = this.params.status;
      const { data } = await api.countEmails(status, this.isGeneral);
      return data;
    },
    getFirstEmails: async function() {
      try {
        if (this.page !== 0) {
          this.paginationLoading = true;
          this.page = 0;
          await this.getData();
          this.setShownEmails(this.emails);
        } else throw new Error("You've already reached the first emails");
      } catch (err) {
        console.error(err.message);
        Swal.fire({ icon: "error", title: "Oops...", text: err.message });
      } finally {
        this.paginationLoading = false;
      }
    },
    getPreviousEmails: async function() {
      try {
        if (this.page > 0) {
          this.paginationLoading = true;
          this.page--;
          await this.getData();
          this.setShownEmails(this.emails);
        } else throw new Error("You've already reached the first emails");
      } catch (err) {
        console.error(err.message);
        Swal.fire({ icon: "error", title: "Oops...", text: err.message });
      } finally {
        this.paginationLoading = false;
      }
    },
    getNextEmails: async function() {
      try {
        // Delete dead code
        // const emailsNumber = await this.getEmailsNumber();
        const skip = this.skip(this.page + 1);
        if (skip <= this.emailsCount) {
          this.paginationLoading = true;
          this.page++;
          await this.getData();
          this.setShownEmails(this.emails);
        } else throw new Error("No more emails to show");
      } catch (err) {
        console.error(err.message);
        if (err.response && err.response.status === 404) {
          if (
            err.response.data &&
            err.response.data.detail === "User not found"
          )
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Session expired"
            }).then(() => {
              this.logoutUser();
              this.$router.push({ name: "login", params: { noAuth: true } });
            });
        } else
          Swal.fire({ icon: "error", title: "Oops...", text: err.message });
      } finally {
        this.paginationLoading = false;
      }
    },
    getLastEmails: async function() {
      try {
        // Delete dead code
        // const emailsNumber = await this.getEmailsNumber();
        const lastPage = Number.parseInt(this.emailsCount / this.limit);
        if (lastPage > this.page) {
          this.paginationLoading = true;
          this.page = lastPage;
          await this.getData();
          this.setShownEmails(this.emails);
        } else throw new Error("No more emails to show");
      } catch (err) {
        console.error(err.message);
        if (err.response && err.response.status === 404) {
          if (
            err.response.data &&
            err.response.data.detail === "User not found"
          )
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Session expired"
            }).then(() => {
              this.logoutUser();
              this.$router.push({ name: "login", params: { noAuth: true } });
            });
        } else
          Swal.fire({ icon: "error", title: "Oops...", text: err.message });
      } finally {
        this.paginationLoading = false;
      }
    },
    search: function(value) {
      // Delete dead code
      // get the searchBox value
      let searchCriteria = this.$el.querySelector(".selectbox").value;
      // make http request to get the data. Then assign it to settabledata
      // this.searchEmails({ searchCriteria, value });
      const payload = {};
      payload[searchCriteria] = value;
      this.getData(searchCriteria, value);
    },
    getData: async function(searchCriteria, searchValue) {
      try {
        const payload = {};
        payload.skip = this.skip();
        payload.status = this.params?.status;
        payload.limit = config.emailsPerTable;
        if (searchCriteria && searchValue) {
          payload[searchCriteria] = searchValue;
        } else {
          delete payload[searchCriteria];
        }
        await this.getEmails(payload);
        this.isLoading = false;
        // if (this.type !== "ner")
        //   this.setSorting({
        //     column: "last_modified",
        //     dir: this.params.dir
        //   });
        this.sortEmails();
      } catch (err) {
        console.error("[EMLST ERROR]:", err.message);
        if (!err.response)
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `Could not get response from Charlie: ${err.message}`
          }).then(() => this.$router.push({ name: "home" }));
        else if (err.response.status === 403) {
          this.logoutUser();
          this.$router.push({ name: "login", params: { noAuth: true } });
        } else {
          const errMessage = err.response.data.detail;
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: errMessage
          }).then(() => this.$router.push({ name: "home" }));
        }
      }
    }
  },
  created: function() {
    this.$store.commit("setParams", emailListConfig[this.type]);
    this.$store.commit("setGeneral", { isGeneral: this.isGeneral });

    this.isLoading = true;
    this.getData();
    this.setSorting({ column: "last_modified" });
    this.intervalID = setInterval(() => {
      this.getData();
      // this.search(this.$el.querySelector(".searchbar").value);
    }, config.emailsTimeout);
  },
  beforeDestroy: async function() {
    await clearInterval(this.intervalID);
  }
};
</script>

<style lang="scss" coped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#email-list {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.title {
  padding: 20px 0;
  color: var(--app-font-color);
}

.list-table {
  flex-grow: 1;
  padding-left: 50px;
  padding-right: 50px;
  margin-bottom: 50px;
}

.pagination {
  align-self: flex-end;
  padding-left: 50px;
  padding-right: 50px;

  @include for-phone-only {
    align-self: center;
  }
}
</style>

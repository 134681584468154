<template>
  <div class="pagination">
    <button class="pag-button outer" @click="getLastEmails">
      <font-awesome-icon icon="angle-double-right" />
    </button>
    <button class="pag-button" @click="getNextEmails">
      <font-awesome-icon icon="angle-right" />
    </button>
    <label class="pag-label">{{ intervalLabel }}</label>
    <button class="pag-button" @click="getPreviousEmails">
      <font-awesome-icon icon="angle-left" />
    </button>
    <button class="pag-button outer" @click="getFirstEmails">
      <font-awesome-icon icon="angle-double-left" />
    </button>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "PaginationPanel",
  props: {
    page: {
      type: Number,
      default: 0
    },
    limit: Number
  },
  computed: {
    ...mapState("emailList", ["emails"]),
    skip: function() {
      return this.limit * this.page;
    },
    intervalLabel: function() {
      let interval = "";
      const carry = this.emails.length === 0 ? 0 : 1;
      interval = `${this.skip + carry}-${this.skip + this.emails.length}`;
      return interval;
    }
  },
  methods: {
    getFirstEmails: function() {
      this.$emit("getFirstEmails");
    },
    getPreviousEmails: function() {
      this.$emit("getPreviousEmails");
    },
    getNextEmails: function() {
      this.$emit("getNextEmails");
    },
    getLastEmails: function() {
      this.$emit("getLastEmails");
    }
  }
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.pagination {
  display: flex;
  flex-direction: row-reverse;
}

.pag-button {
  color: #ffffff;
  background-color: var(--sidebar-bg-color);
  text-decoration: none;
  padding: 3px 10px;
  margin: 10px;
  border-radius: 10%;
  border: none;
  outline: none;

  &:hover {
    background-color: var(--button-hover-color);
    cursor: pointer;
  }
}

.outer {
  margin: auto 0;
}

label.pag-label {
  color: var(--sidebar-bg-color);
  display: flex;
  font-weight: bold;
  align-items: center;
  justify-content: center;
}
</style>
